<template>
  <div class="form-maker">
    <r-group-input
      v-model="items"
      :template="{
        type: { type: 'r-text-input' },
        props: { default: { label: '' } },
        access: { default: [] },
      }"
    >
      <template v-slot="{ item }">
        <template v-if="item.type">
          <div>
            <r-btn icon @click="open(item)">
              <r-img
                src="/storage/svg/setting.svg"
                alt="setting"
                is-svg
                width="30"
                height="30"
              ></r-img>
            </r-btn>
          </div>
          <component
            :is="item.type"
            v-bind="item.props"
            :upload-link="item.type === 'r-file-input' ? '/' : undefined"
          ></component>
        </template>
        <r-select-input
          v-else
          :label="$t('type')"
          v-model="item.type"
          :items="inputs"
          just-value
          @update:model-value="open(item)"
        ></r-select-input>
      </template>
    </r-group-input>
    <r-modal v-model="show" :closebtn="false">
      <div class="pa-5">
        <r-form v-model="valid">
          <r-text-input
            v-model="item_setting.props.label"
            :rules="['required']"
            :label="$t('label')"
          ></r-text-input>
          <r-switch-input
            v-model="item_setting.props.tile"
            :label="$t('tile')"
          ></r-switch-input>
          <r-select-input
            v-model="item_setting.props.rules"
            :label="$t('rules')"
            :items="['required', 'email', 'url', 'number', 'english']"
            justValue
            translate
            multiple
          ></r-select-input>
          <r-select-input
            v-if="showAccess"
            v-model="item_setting.access"
            :label="$t('access')"
            :items="['admin', 'user', 'buy', 'vip']"
            justValue
            translate
            multiple
          ></r-select-input>
          <component
            v-for="(v, k) in props[item_setting.type]"
            :key="k"
            v-model="item_setting.props[k]"
            :label="$t(k)"
            :is="v.type"
            v-bind="v"
          ></component>
        </r-form>
        <r-btn
          class="color-success mt-5"
          :disabled="!valid"
          rounded
          block
          @click="show = false"
          >{{ $t("ok") }}</r-btn
        >
      </div>
    </r-modal>
  </div>
</template>

<script>
export default {
  name: "formMaker",
  props: {
    modelValue: Array,
    showAccess: Boolean,
  },
  data() {
    return {
      valid: false,
      items: this.modelValue ? this.modelValue : [],
      props: {
        "r-text-input": {},
        "r-number-input": {
          step: { type: "r-number-input", step: 0.000000001 },
          split: { type: "r-number-input" },
          min: { type: "r-number-input", step: 0.000000001 },
          max: { type: "r-number-input", step: 0.000000001 },
        },
        "r-switch-input": {
          label2: { type: "r-text-input" },
        },
        "r-text-area": {
          noResize: { type: "r-switch-input" },
          rows: { type: "r-text-input" },
        },

        "r-color-input": {},
        "r-date-input": {
          withTime: { type: "r-switch-input" },
        },
        "r-file-input": {
          accept: {
            type: "r-select-input",
            items: [
              { name: this.$t("all"), value: "*" },
              { name: this.$t("image"), value: "image/*" },
              { name: this.$t("video"), value: "video/*" },
            ],
            rules: ["required"],
            justValue: true,
          },
          size: { type: "r-number-input" },
          maxFileSize: { type: "r-number-input" },
          wPH: { type: "r-text-input" },
        },
        "r-tel-input": {
          defaultCountry: { type: "r-text-input" },
          select: { type: "r-switch-input" },
          required: { type: "r-switch-input" },
        },
        "r-time-input": {
          withSec: { type: "r-switch-input" },
          is24Hour: { type: "r-switch-input" },
        },
        "r-time-range-input": {
          withSec: { type: "r-switch-input" },
          is24Hour: { type: "r-switch-input" },
        },
        "r-address-input": {
          required: { type: "r-switch-input" },
          hideState: { type: "r-switch-input" },
          hideCity: { type: "r-switch-input" },
          hideStreet: { type: "r-switch-input" },
          hideZipCode: { type: "r-switch-input" },
        },
        "r-checkbox-input": {},
        "r-json-input": {
          valueType: {
            type: "r-select-input",
            items: ["text", "number"],
            justValue: true,
            rules: ["required"],
            firstSelect: true,
            translate: true,
          },
        },

        "r-range-input": {
          step: { type: "r-number-input", step: 0.000000001 },
          min: { type: "r-number-input", step: 0.000000001 },
          max: { type: "r-number-input", step: 0.000000001 },
          tooltipAlways: { type: "r-switch-input" },
          isRange: { type: "r-switch-input" },
        },
        "r-rating-input": {
          count: { type: "r-number-input", step: 1 },
        },
        "r-radio-input": {
          rounded: { type: "r-switch-input" },
          justValue: { type: "r-switch-input" },
          items: {
            type: "r-select-input",
            tags: true,
            justValue: true,
            multiple: true,
          },
        },
        "r-select-input": {
          tags: { type: "r-switch-input" },
          multiple: { type: "r-switch-input" },
          justValue: { type: "r-switch-input" },
          openToTop: { type: "r-switch-input" },
          firstSelect: { type: "r-switch-input" },
          items: {
            type: "r-select-input",
            tags: true,
            justValue: true,
            multiple: true,
          },
        },
      },
      item_setting: null,
      show: false,
    };
  },
  computed: {
    inputs() {
      let r = Object.keys(this.props);
      let k = [];
      r.forEach((item) => {
        k.push({
          name: this.$t(item.replace("r-", "")),
          value: item,
        });
      });
      return k;
    },
  },
  watch: {
    modelValue() {
      this.items = this.modelValue ? this.modelValue : [];
    },
    items: {
      handler: function () {
        this.$emit("update:modelValue", this.items);
      },
      deep: true,
    },
  },
  methods: {
    open(item) {
      if (!item) {
        return;
      }
      this.item_setting = item;
      if (this.showAccess && !this.item_setting.access) {
        this.item_setting.access = [];
      }
      if (!item.props) {
        this.item_setting.props = {};
      }
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
