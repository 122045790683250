<template>
  <template v-if="$r.store.pages">
    <online-component
      v-for="item_page in $r.store.pages.line"
      :key="item_page._key"
      :k="item_page._key"
      :comp="$r.store.pages.components[item_page.component]"
      :setting="item_page.setting[device]"
      :requires="$r.store.pages.components"
    ></online-component>
    <div
      v-if="$r.store.pages.line.length === 0"
      style="height: 400px; background-color: #eeebec"
      class="d-flex v-center h-center display-1 cursor-pointer"
      @click="paste"
    >
      +
    </div>
  </template>
  <div
    v-else
    class="d-flex h-center v-center title color-grey-lighten-3"
    style="height: 500px"
    key="0"
  >
    <r-progress-circle
      indeterminate
      size="100"
      class="color-one-text"
    ></r-progress-circle>
  </div>
</template>

<script>
import OnlineComponent from "@/components/makeup/onlineComponent";
export default {
  name: "pageContent",
  components: { OnlineComponent },
  data() {
    return {
      device: this.$r.breakpoint.lgAndUp ? "lg" : "sm",
    };
  },
  methods: {
    paste() {
      this.$r.store.component_paste = "";
      setTimeout(() => {
        this.$r.store.component_paste = "1";
      }, 10);
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
