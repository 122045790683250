<template>
  <div
    ref="c"
    class="online-component"
    :class="{
      'component-active': isActive,
      'component-disable': !setting.show_component,
    }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="component-edit px-2">
      <r-btn class="color-white-text" outlined size="small" @click="paste">
        {{ $t("paste") }}
      </r-btn>
      <r-btn class="color-white-text" icon text @click="move(-1)">
        <r-icon v-html="$r.icons.chevron_up"></r-icon>
      </r-btn>
      <r-btn class="color-white-text" icon text @click="move(1)">
        <r-icon v-html="$r.icons.chevron_down"></r-icon>
      </r-btn>
      <r-btn class="color-white-text" icon text @click="edit">
        <r-icon v-html="$r.icons.edit"></r-icon>
      </r-btn>
      <r-btn-confirm
        class="color-white-text"
        icon
        text
        @click="del"
        :loading="loading"
      >
        <r-icon v-html="$r.icons.delete"></r-icon>
      </r-btn-confirm>
    </div>
    <div class="component-holder" v-if="page">
      <component :is="page" v-bind="settingLazy"></component>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, markRaw } from "vue";

export default {
  name: "onlineComponent",
  props: {
    k: String,
    comp: Object,
    requires: Object,
    setting: Object,
  },
  data() {
    return {
      currentInstance: getCurrentInstance(),
      loaded: false,
      styles: "",
      hover: false,
      loading: false,
      settingLazy: null,
    };
  },
  created() {
    if (!this.comp) {
      return;
    }
    const site = this.$axios.defaults.headers["site"];
    this.settingLazy = JSON.parse(
      JSON.stringify(this.setting).replace(
        /storage\//g,
        `builder/${site}/storage/`
      )
    );
    this.regRequires(Object.values(this.requires));
  },
  computed: {
    isActive() {
      if (this.hover) {
        return !(this.comp.title === "page-router" && this.$r.store.pages);
      }

      return false;
    },
    page() {
      if (!this.loaded) {
        return false;
      }

      return this.currentInstance.appContext.components[
        "c-" + this.comp["title"]
      ];
    },
  },
  methods: {
    move(n) {
      this.$r.store.component_move = null;
      setTimeout(() => {
        this.$r.store.component_move = this.k + "," + n;
      }, 10);
    },
    paste() {
      this.$r.store.component_paste = "";
      setTimeout(() => {
        this.$r.store.component_paste = this.k;
      }, 10);
    },
    edit() {
      this.$r.store.component_edit = "";
      setTimeout(() => {
        this.$r.store.component_edit = this.k;
      }, 10);
    },
    del() {
      this.loading = true;
      this.$r.store.component_del = "";
      setTimeout(() => {
        this.$r.store.component_del = this.k;
      }, 10);
    },
    regRequires(requires) {
      if (requires.length === 0) {
        this.loaded = true;
        return true;
      } else {
        const a = Object.keys(this.currentInstance.appContext.components);
        this.styles = "";
        requires.forEach((item) => {
          if (!a.includes(`c-${item["title"]}`)) {
            const c = this.load(item["title"], item.code.html, item.code.js);
            if (!c) {
              return;
            }
            window.App.component("c-" + item["title"], c);
            if (item.code.style) {
              this.styles += item.code.style + " ";
            }
          }
        });
        if (this.styles) {
          let s = document.createElement("style");
          s.innerText = this.styles.replace(/[\r\n\t]/g, "");
          s.setAttribute("f", "component-" + this.comp["title"]);
          s.setAttribute("type", "text/css");
          window.document.head.append(s);
        }
        this.loaded = true;
        return true;
      }
    },
    load(name, html, js) {
      try {
        return markRaw({
          name: "c-" + name,
          template: html,
          ...eval("Object({" + js + "})"),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/renusify/style/include";

.online-component {
  position: relative;

  .component-holder {
    z-index: 0;
  }

  .component-edit {
    position: absolute;
    left: 0;
    display: none;
    z-index: 1;
    background-color: rgba(15, 120, 65, 0.8);
    border-radius: 2px;
  }

  &.component-active {
    border: 2px rgba(46, 139, 87, 0.8) solid;

    .component-edit {
      display: block;
    }
  }

  .component-disable {
    opacity: 0.6;
    background-color: #c7c4c4;
  }
}
</style>
