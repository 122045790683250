<template>
  <r-select-input
    :model-value="modelValue"
    :key="k"
    @update:model-value="emit"
    just-value
    translate
    :items="
      $r.store.fieldinput[items_id]
        ? Object.keys($r.store.fieldinput[items_id])
        : []
    "
  ></r-select-input>
</template>

<script>
export default {
  name: "r-field-input",
  props: {
    modelValue: String,
    isSm: Boolean,
  },
  data() {
    return {
      items_id: null,
      k: 0,
    };
  },
  created() {
    if (!this.$r.store.fieldinput) {
      this.$r.store.fieldinput = {};
    }

    if (
      this.$r.store.formSettingData &&
      this.$r.store.formSettingData[this.isSm ? "sm" : "lg"].id
    ) {
      this.items_id = this.$r.store.formSettingData[this.isSm ? "sm" : "lg"].id;
    }
    if (!this.$r.store.fieldinput[this.items_id] && this.items_id) {
      this.$r.store.fieldinput[this.items_id] = {};
      this.get_fields();
    }
  },
  watch: {
    "$r.store.fieldinput": {
      handler: function () {
        this.k++;
      },
      deep: true,
    },
  },
  methods: {
    emit(e) {
      if (e) {
        this.$emit("update:model-value", e);
      }
    },
    get_fields() {
      this.$axios
        .get("/admin/items/setting/" + this.items_id)
        .then(({ data }) => {
          this.$r.store.fieldinput[this.items_id]["_id"] = "1";
          data["fields"].forEach((item) => {
            if (item.props && item.props.label) {
              this.$r.store.fieldinput[this.items_id][item.props.label] = "1";
            }
          });
          this.$r.store.fieldinput[this.items_id]["category"] = "1";
          this.$r.store.fieldinput[this.items_id]["updated_at"] = "1";
          this.$r.store.fieldinput[this.items_id]["created_at"] = "1";
        });
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
